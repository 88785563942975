// If you want to override variables do it here
@import "variables";

// Import styles
@import "~@coreui/coreui/scss/coreui";

// If you want to add something do it here
@import "custom";

@import "~@ng-select/ng-select/themes/default.theme.css";

@import url("../assets/fonts/elegant_font/HTML_CSS/style.css");

@import url("../assets/fonts/font-awesome/css/font-awesome.css");

body{
    background: #0b172c !important;
    font-family: 'Urbanist', sans-serif !important;
    overflow-y: scroll !important;
    height: 100% !important;
}
.app-body .main {
  margin: 50px 0;
}

a:hover{
    text-decoration: none;
}


.btn:focus, .btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(238 41 79 / 0%) !important;
}

.space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0px * var(--cb-space-y-reverse));
  }
  
  .space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0px * var(--cb-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1px * var(--cb-space-y-reverse));
  }
  
  .space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1px * var(--cb-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(2px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(2px * var(--cb-space-y-reverse));
  }
  
  .space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(2px * var(--cb-space-x-reverse));
    margin-left: calc(2px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(3px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(3px * var(--cb-space-y-reverse));
  }
  
  .space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(3px * var(--cb-space-x-reverse));
    margin-left: calc(3px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(4px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(4px * var(--cb-space-y-reverse));
  }
  
  .space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(4px * var(--cb-space-x-reverse));
    margin-left: calc(4px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(5px * var(--cb-space-y-reverse));
  }
  
  .space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(5px * var(--cb-space-x-reverse));
    margin-left: calc(5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(10px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(10px * var(--cb-space-y-reverse));
  }
  
  .space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(10px * var(--cb-space-x-reverse));
    margin-left: calc(10px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(15px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(15px * var(--cb-space-y-reverse));
  }
  
  .space-x-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(15px * var(--cb-space-x-reverse));
    margin-left: calc(15px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(20px * var(--cb-space-y-reverse));
  }
  
  .space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(20px * var(--cb-space-x-reverse));
    margin-left: calc(20px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(25px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(25px * var(--cb-space-y-reverse));
  }
  
  .space-x-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(25px * var(--cb-space-x-reverse));
    margin-left: calc(25px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(30px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(30px * var(--cb-space-y-reverse));
  }
  
  .space-x-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(30px * var(--cb-space-x-reverse));
    margin-left: calc(30px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(35px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(35px * var(--cb-space-y-reverse));
  }
  
  .space-x-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(35px * var(--cb-space-x-reverse));
    margin-left: calc(35px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(40px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(40px * var(--cb-space-y-reverse));
  }
  
  .space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(40px * var(--cb-space-x-reverse));
    margin-left: calc(40px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(45px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(45px * var(--cb-space-y-reverse));
  }
  
  .space-x-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(45px * var(--cb-space-x-reverse));
    margin-left: calc(45px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(50px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(50px * var(--cb-space-y-reverse));
  }
  
  .space-x-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(50px * var(--cb-space-x-reverse));
    margin-left: calc(50px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(55px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(55px * var(--cb-space-y-reverse));
  }
  
  .space-x-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(55px * var(--cb-space-x-reverse));
    margin-left: calc(55px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(60px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(60px * var(--cb-space-y-reverse));
  }
  
  .space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(60px * var(--cb-space-x-reverse));
    margin-left: calc(60px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(65px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(65px * var(--cb-space-y-reverse));
  }
  
  .space-x-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(65px * var(--cb-space-x-reverse));
    margin-left: calc(65px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(70px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(70px * var(--cb-space-y-reverse));
  }
  
  .space-x-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(70px * var(--cb-space-x-reverse));
    margin-left: calc(70px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(75px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(75px * var(--cb-space-y-reverse));
  }
  
  .space-x-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(75px * var(--cb-space-x-reverse));
    margin-left: calc(75px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(80px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(80px * var(--cb-space-y-reverse));
  }
  
  .space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(80px * var(--cb-space-x-reverse));
    margin-left: calc(80px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(85px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(85px * var(--cb-space-y-reverse));
  }
  
  .space-x-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(85px * var(--cb-space-x-reverse));
    margin-left: calc(85px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(90px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(90px * var(--cb-space-y-reverse));
  }
  
  .space-x-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(90px * var(--cb-space-x-reverse));
    margin-left: calc(90px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(95px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(95px * var(--cb-space-y-reverse));
  }
  
  .space-x-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(95px * var(--cb-space-x-reverse));
    margin-left: calc(95px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(100px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(100px * var(--cb-space-y-reverse));
  }
  
  .space-x-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(100px * var(--cb-space-x-reverse));
    margin-left: calc(100px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(105px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(105px * var(--cb-space-y-reverse));
  }
  
  .space-x-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(105px * var(--cb-space-x-reverse));
    margin-left: calc(105px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(110px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(110px * var(--cb-space-y-reverse));
  }
  
  .space-x-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(110px * var(--cb-space-x-reverse));
    margin-left: calc(110px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(115px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(115px * var(--cb-space-y-reverse));
  }
  
  .space-x-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(115px * var(--cb-space-x-reverse));
    margin-left: calc(115px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(120px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(120px * var(--cb-space-y-reverse));
  }
  
  .space-x-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(120px * var(--cb-space-x-reverse));
    margin-left: calc(120px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1px * var(--cb-space-y-reverse));
  }
  
  .space-x-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1px * var(--cb-space-x-reverse));
    margin-left: calc(1px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0.5px * var(--cb-space-y-reverse));
  }
  
  .space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0.5px * var(--cb-space-x-reverse));
    margin-left: calc(0.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(1.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(1.5px * var(--cb-space-y-reverse));
  }
  
  .space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(1.5px * var(--cb-space-x-reverse));
    margin-left: calc(1.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(2.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(2.5px * var(--cb-space-y-reverse));
  }
  
  .space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(2.5px * var(--cb-space-x-reverse));
    margin-left: calc(2.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(3.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(3.5px * var(--cb-space-y-reverse));
  }
  
  .space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(3.5px * var(--cb-space-x-reverse));
    margin-left: calc(3.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(0px * var(--cb-space-y-reverse));
  }
  
  .-space-x-0 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(0px * var(--cb-space-x-reverse));
    margin-left: calc(0px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1px * var(--cb-space-y-reverse));
  }
  
  .-space-x-1 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1px * var(--cb-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-2px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-2px * var(--cb-space-y-reverse));
  }
  
  .-space-x-2 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-2px * var(--cb-space-x-reverse));
    margin-left: calc(-2px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-3px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-3px * var(--cb-space-y-reverse));
  }
  
  .-space-x-3 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-3px * var(--cb-space-x-reverse));
    margin-left: calc(-3px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-4px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-4px * var(--cb-space-y-reverse));
  }
  
  .-space-x-4 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-4px * var(--cb-space-x-reverse));
    margin-left: calc(-4px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-5px * var(--cb-space-y-reverse));
  }
  
  .-space-x-5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-5px * var(--cb-space-x-reverse));
    margin-left: calc(-5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-10px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-10px * var(--cb-space-y-reverse));
  }
  
  .-space-x-10 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-10px * var(--cb-space-x-reverse));
    margin-left: calc(-10px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-15px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-15px * var(--cb-space-y-reverse));
  }
  
  .-space-x-15 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-15px * var(--cb-space-x-reverse));
    margin-left: calc(-15px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-20px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-20px * var(--cb-space-y-reverse));
  }
  
  .-space-x-20 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-20px * var(--cb-space-x-reverse));
    margin-left: calc(-20px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-25px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-25px * var(--cb-space-y-reverse));
  }
  
  .-space-x-25 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-25px * var(--cb-space-x-reverse));
    margin-left: calc(-25px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-30px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-30px * var(--cb-space-y-reverse));
  }
  
  .-space-x-30 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-30px * var(--cb-space-x-reverse));
    margin-left: calc(-30px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-35px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-35px * var(--cb-space-y-reverse));
  }
  
  .-space-x-35 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-35px * var(--cb-space-x-reverse));
    margin-left: calc(-35px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-40px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-40px * var(--cb-space-y-reverse));
  }
  
  .-space-x-40 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-40px * var(--cb-space-x-reverse));
    margin-left: calc(-40px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-45px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-45px * var(--cb-space-y-reverse));
  }
  
  .-space-x-45 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-45px * var(--cb-space-x-reverse));
    margin-left: calc(-45px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-50px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-50px * var(--cb-space-y-reverse));
  }
  
  .-space-x-50 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-50px * var(--cb-space-x-reverse));
    margin-left: calc(-50px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-55px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-55px * var(--cb-space-y-reverse));
  }
  
  .-space-x-55 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-55px * var(--cb-space-x-reverse));
    margin-left: calc(-55px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-60px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-60px * var(--cb-space-y-reverse));
  }
  
  .-space-x-60 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-60px * var(--cb-space-x-reverse));
    margin-left: calc(-60px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-65px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-65px * var(--cb-space-y-reverse));
  }
  
  .-space-x-65 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-65px * var(--cb-space-x-reverse));
    margin-left: calc(-65px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-70px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-70px * var(--cb-space-y-reverse));
  }
  
  .-space-x-70 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-70px * var(--cb-space-x-reverse));
    margin-left: calc(-70px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-75px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-75px * var(--cb-space-y-reverse));
  }
  
  .-space-x-75 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-75px * var(--cb-space-x-reverse));
    margin-left: calc(-75px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-80px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-80px * var(--cb-space-y-reverse));
  }
  
  .-space-x-80 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-80px * var(--cb-space-x-reverse));
    margin-left: calc(-80px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-85px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-85px * var(--cb-space-y-reverse));
  }
  
  .-space-x-85 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-85px * var(--cb-space-x-reverse));
    margin-left: calc(-85px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-90px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-90px * var(--cb-space-y-reverse));
  }
  
  .-space-x-90 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-90px * var(--cb-space-x-reverse));
    margin-left: calc(-90px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-95px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-95px * var(--cb-space-y-reverse));
  }
  
  .-space-x-95 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-95px * var(--cb-space-x-reverse));
    margin-left: calc(-95px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-100px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-100px * var(--cb-space-y-reverse));
  }
  
  .-space-x-100 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-100px * var(--cb-space-x-reverse));
    margin-left: calc(-100px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-105px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-105px * var(--cb-space-y-reverse));
  }
  
  .-space-x-105 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-105px * var(--cb-space-x-reverse));
    margin-left: calc(-105px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-110px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-110px * var(--cb-space-y-reverse));
  }
  
  .-space-x-110 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-110px * var(--cb-space-x-reverse));
    margin-left: calc(-110px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-115px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-115px * var(--cb-space-y-reverse));
  }
  
  .-space-x-115 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-115px * var(--cb-space-x-reverse));
    margin-left: calc(-115px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-120px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-120px * var(--cb-space-y-reverse));
  }
  
  .-space-x-120 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-120px * var(--cb-space-x-reverse));
    margin-left: calc(-120px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1px * var(--cb-space-y-reverse));
  }
  
  .-space-x-px > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1px * var(--cb-space-x-reverse));
    margin-left: calc(-1px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-0.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-0.5px * var(--cb-space-y-reverse));
  }
  
  .-space-x-0\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-0.5px * var(--cb-space-x-reverse));
    margin-left: calc(-0.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-1.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-1.5px * var(--cb-space-y-reverse));
  }
  
  .-space-x-1\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-1.5px * var(--cb-space-x-reverse));
    margin-left: calc(-1.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-2.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-2.5px * var(--cb-space-y-reverse));
  }
  
  .-space-x-2\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-2.5px * var(--cb-space-x-reverse));
    margin-left: calc(-2.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .-space-y-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 0;
    margin-top: calc(-3.5px * calc(1 - var(--cb-space-y-reverse)));
    margin-bottom: calc(-3.5px * var(--cb-space-y-reverse));
  }
  
  .-space-x-3\.5 > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 0;
    margin-right: calc(-3.5px * var(--cb-space-x-reverse));
    margin-left: calc(-3.5px * calc(1 - var(--cb-space-x-reverse)));
  }
  
  .space-y-reverse > :not([hidden]) ~ :not([hidden]) {
    --cb-space-y-reverse: 1
  ;
  }
  
  .space-x-reverse > :not([hidden]) ~ :not([hidden]) {
    --cb-space-x-reverse: 1
  ;
  }


  a {
    color: #fff;
  }

  .custom-select {
    display: inline-block;
    width: 100%;
    height: 45px;
    padding: 0.375rem 1.75rem 0.375rem 0.75rem;
    font-size:16px;
    font-weight: 400;
    line-height: 1.5;
    color: #5c6873;
    vertical-align: middle;
    background-color: #fff;
    border: 1px solid #b6c1cb;
    border-radius: 30px;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin-bottom: 10px;
}

.custom-select:focus {
  border-color: #181818;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(51, 253, 0, 0.05);
}


.custom-select option{
  height: 30px !important;
  padding: 10px 0;
}

.form-control {
  display: block;
  width: 100%;
  height:38px;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #fff !important;
  background-clip: padding-box;
  border: 1px solid #30789f;
  background-color: #0e2654!important;
  border-radius:4px;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  font-family: 'Roboto', sans-serif;
  position: relative;
}

button{
  font-family: 'Urbanist', sans-serif !important;
}

.item_filter_group .panel-heading {
  font-weight: 600 !important;
  background: #1B2D4B !important;
  padding: 0.25rem 0.75rem;
  color: #fff;
}

.item_filter_group .panel-heading:after {
  content: "\f107"; 
  position: absolute;
  font-family: "FontAwesome";
  z-index: 99;
  right: 10px;
  top: 10px;
  font-size:18px;
  color: #B1B1B1;
}

.item_filter_group .panel-collapse {
  background: #1b2d4b;
}
.item_filter_group .card-header {
  border-bottom: solid 1px rgba(255, 255, 255, 0.1) !important;
}
.item_filter_group  .card {
  border: 0px solid #c8ced3;
}
.item_filter_group {
  margin-bottom: 0;
  padding: 0 30px;
  border-radius: 12px;
}

.item_filter_group .de_checkbox {
  position: relative;
  display: block;
  padding-left: 25px;
  padding-bottom: 10px;
  padding-right: 25px;
}
.de_checkbox label{
  font-family: "Saira", sans-serif;
    font-weight: 300;
    font-size: 16px;
    color: #B1B1B1 !important;
}
.item_filter_group h4 {
  color: #56e2b8;
  padding-bottom: 15px;
  font-weight: 400;
}
.item_filter_group .de_checkbox input[type=checkbox] {
  display: none;
  appearance: none;
  border: none;
  outline: none;
}
.item_filter_group .de_form input[type=checkbox]:checked + label {
  border: none;
}

.item_filter_group .de_form input[type=checkbox]:checked + label::before {
  content: "\f00c";
  color: #ffffff;
  border: rgba(0, 0, 0, 0);
  background: #000000;
}
.item_filter_group .de_form input[type=checkbox] + label::before {
  position: absolute;
  content: "";
  display: inline-block;
  font-family: "FontAwesome";
  margin-right: 10px;
  border: solid 2px rgba(0, 0, 0, 0.2);
  width: 16px;
  height: 16px;
  margin-top: 5px;
  left: 0;
  font-size: 11px;
  padding: 1px 3px 0 3px;
  line-height: 15px;
  border-radius: 4px;
}

.item_filter_group .btn-link:hover {
  color: #fff;
  text-decoration: underline;
}
.item_filter_group .de_checkbox select {
  display: inline-block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 1.75rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  vertical-align: middle;
  background: #fff url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAQAAAD9CzEMAAABLElEQVR4Ae3BP0jUcQAH0HeXqf0hSUgKImooqKElG1wKV6mlqbGtwdFBaohWoSXCIWiwlsBBXKIhGqShlpBcDGooaiiEQAkF0ftEgxCR9Tu9G4zve4qiKIqdqu6hS5p12YS6SsbFsouaMWhFjKvkpohF/ao6b0nEDRWNiVhwRhWnfBVxV2U190V8dsK/HPNRxISaJtQ9FvHeEX/T562IKR2atNsTEXN6babHrIhnumzBHjMiXtnvT/Z6IeKlfbbogNcinuv2u05PRbxx0DYcMi9iWodf7TIp4p3DtumoDyIeqdtQ80DEJ8e1wElfRNyz4Y6IBae1yFnfRNzy020Ri85poQHfRYwYFrHsghYbsioaGmLVkDa4Yk3Euqva5JqGhuvaaNSooiiK4v/zA4DSbQnBMNnIAAAAAElFTkSuQmCC) right 0.75rem center/8px 10px no-repeat;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}

.btn-link {
  font-weight: 400;
  color: #eff8f7;
  text-decoration: none;
}


label {
  color: #fff !important;
  font-weight: 600;
}

body, .mat-body, .mat-body-1, .mat-typography, input, button, select, optgroup, textarea {
  font-family: 'Saira', sans-serif !important;
}

h1,h2,h3,h4,h5 {
  font-family: 'Orbitron', sans-serif !important;
}


.form-control:focus {
  color: #5c6873;
  background-color: #fff;
  border-color: #fff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgb(0 0 0 / 0%);
}

.btn-primary {
  border-radius: 20px !important;
}
.nav-tabs .nav-link.active {
  color: #000;
  background: #c83e69;
  border-color: #c8ced300;
  border-bottom-color: #fff0;
}

.div_tab .tab-content {
  margin-top: -1px;
  background: #fff;
  border: 0px solid #c8ced3;
  box-shadow: 0px 3px 16px rgb(47 83 109 / 12%);
  border-radius: 20px;
}

.nav-tabs {
  border-bottom: 0px solid #c8ced3;
}

.nav-tabs .nav-link {
  border: 0;
  border-radius: 30px;
}

.nav-tabs .nav-link:hover {
  color: #000;
  background: #c83e69;
  border-color: #c8ced300;
  border-bottom-color: #fff0;
}

.topheader .dropdown-item i {
  display: inline-block;
  width: 20px;
  margin-right: 10px;
  margin-left: -10px;
  color: #fff;
  text-align: center;
  font-size: 18px;
  position: relative;
  top: 2px;
}

.ng-select.ng-select-single .ng-select-container {
  color: #fff  !important;
  border: 1px solid #30789f;
  background-color: #0e2654!important;
  border-radius: 4px;
  min-height: 36px;
  align-items: center;
}

.ng-dropdown-panel.ng-select-bottom {
background-color: #0d0734!important;
}

.ng-dropdown-panel .ng-dropdown-panel-items .ng-option {
background-color: #0d0734;
color: #fff;
}
.card-body-filter-action button {
  margin-left: 10px;
  font-size: 14px;
  height: 35px;
  border: 0;
  position: absolute;
  top: 2px;
  right: 22px;
  background: linear-gradient(to right, #48fa8c, #2eeec5, #1ae7ea);
}

.main h2{
  color: #fff;
  font-size: 18px;
}



.modal-content {
  background-color: #45165f !important;
  border: 1px solid rgb(97 103 151) !important;
}

.modal-title{
  color: #fff !important;
  font-size: 22px !important;
}

.modal-body p{
  color: #fff;
  font-size: 16px;
}

.modal-body h4{
  color: #fff;
  font-size: 18px;
}

.modal-body .form-control{
  background: #fff !important;
    color: #000 !important;
}

.close {
  float: right;
  font-size: 1.3125rem;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-shadow: 0 0px 0 #fff;
  opacity: 1;
}

.logtxt h2{
  text-transform: uppercase;
    font-size: 42px !important;
    font-weight: 900 !important;
    color: #fff;
    line-height: 1.4;
}

.copyright{
    padding: 20px 0 20px 0;
    border-top: solid 1px #1B2D4B;
    background: #0b172c !important;
    color: #fff;
    
}
.login h3{
  font-size: 22px;
  margin-bottom: 15px;
  font-family: "Saira", sans-serif !important;
  color: #fff;
  font-weight: 500;
}

.login h3{
  font-size: 22px;
  margin-bottom: 15px;
  font-family: "Saira", sans-serif !important;
  color: #fff;
  font-weight: 500;
}




.infoItem span {
  width: 10em;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.dotcls{
  position: absolute;
    right: 0;
    top: 0;
}

@media (max-width:441px) {

.navbar-collapse {
  background: #032662; 
  padding: 10px;
}

}